$offset: 187;
$duration: 1.4s;

@keyframes rotator {
  0% {
    transform-origin: 50% 50%;
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1px, 200px;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 100px, 200px;
    stroke-dashoffset: -15px;
  }
  100% {
    stroke-dasharray: 100px, 200px;
    stroke-dashoffset: -125px;
  }
}

.Spinner {
  display: inline-block;
  animation: rotator 1.4s linear infinite;
}

.Path {
  stroke-dasharray: 80px, 200px;
  stroke-dashoffset: 0;
  animation: dash 1.4s ease-in-out infinite;
  animation-fill-mode: forwards;
  stroke: currentColor;
}

